// i18next-extract-mark-ns-start trustly

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import trustly_monei from 'images/trustly_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {List} from 'components/List';
import {PaymentMethodsLinkList} from 'components/landings/PaymetMethodsLinkList';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {BlogLink} from 'components/links/Blog';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const OrderList = styled.ol``;

const Trustly: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Trustly Payment Method')}
        description={t(
          'Reach more European customers by accepting Trustly payments — the leader in bank transfer payments with' +
            ' more than 6.3 million active users. Get it here. ›› '
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Trustly payment method</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Swedish fintech Trustly is a leader in bank transfer payments in Europe providing
                online banking in the Baltic and Nordic regions, and in the UK. It integrates with
                6,300 banks to collect funds locally across Europe and has 6.3 million active users
                who use their bank account to pay via Trustly. If you’re looking to expand your
                business in these regions, Trustly is a must-have payment method.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
              <Trans>
                <span style={{fontStyle: 'italic', fontSize: '12px'}}>
                  Trustly is available in the following countries: Austria, Germany, Denmark,
                  Estonia, Spain, Finland, United Kingdom, Latvia, Lithuania, Netherlands, Norway,
                  and Sweden.
                </span>
              </Trans>
            </MainTitleContent>

            <MainTitleImg src={trustly_monei} alt="MONEI trustly" title="MONEI trustly" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>What is Trustly and its benefits?</Trans>
              </SectionHeader>

              <Trans parent="p">
                Trustly bank transfer lets customers pay using their online banking credentials.
                Funds are instantly transferred to your business bank account without the risk of
                stolen payment details or fraud.
              </Trans>
              <Trans parent="h3">With Trustly, you can:</Trans>
              <List>
                <Trans parent="li">Support all European currencies</Trans>
                <Trans parent="li">
                  Increase customer loyalty with instant full or partial refunds (according to
                  Trustly, 95% of consumers report same-day refunds make them more loyal to brand)
                </Trans>
                <Trans parent="li">
                  Accept secure and seamless account-to-account payments and withdrawals
                </Trans>
                <Trans parent="li">
                  Eliminate the risk of{' '}
                  <BlogLink slug="chargebacks-prevention">chargebacks</BlogLink> and fraud
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Trustly payment process</Trans>
              </SectionHeader>
              <Trans parent="p">
                Consumers can make payments by selecting Trustly and authorizing the payment through
                their existing online banking environment.
              </Trans>
              <OrderList>
                <Trans parent="li">
                  Shopper selects Trustly as their preferred payment method at checkout
                </Trans>
                <Trans parent="li">
                  Repeat shoppers choose their saved bank and first-time shoppers enter and save
                  bank details for future purchases
                </Trans>
                <Trans parent="li">Shopper logs in to their bank account</Trans>
                <Trans parent="li">
                  Shopper completes the transaction using biometric authentication with Mobile
                  BankID
                </Trans>
              </OrderList>
              <Trans>
                Offer simple and safe payments with Trustly, but don’t stop there. Increase
                conversions by accepting many{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>{' '}
                including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                like{' '}
              </Trans>
              <PaymentMethodsLinkList exclude={['trustly', 'credit-cards']} endTag={t('and')} />.
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
        </Content>
      </Background>
      <IntegrationGuide title={<Trans>Get the Trustly payment integration</Trans>}>
        <Trans>
          Start accepting Trustly bank transfer today. All you have to do is sign up for MONEI. Once
          you’ve been approved,{' '}
          <AnchorLink href="https://support.monei.com/hc/requests/new">contact support</AnchorLink>{' '}
          to configure this payment method in your store.
        </Trans>
      </IntegrationGuide>
    </>
  );
};

export default Trustly;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "trustly"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
